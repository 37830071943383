import React from "react";
import { connect } from "react-redux";
import { searchFindingAids } from "../../actions/searchFindingAids";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

function Inventory(props) {
  function diveLevel(container, level) {
    //look for the box content
    // console.log("container", container, "\n KLEVEL", level);
    return (
      <div className={`all-containers`} key={`${Math.random()}`}>
        {container.map((obj) => {
          // This is always a container "c0# object"
          let current_key = Object.keys(obj);
          let random = Math.random();
          let index = "c0" + level.toString();
          const entry = obj[index];

          if (current_key[0] === "digital-object") {
            return null;
          }

          if (current_key[0] === "inventory_header") {
            return (
              <div key={`${Math.random()}`} className="inventory_header">
                <strong>Inventory Information</strong>
                {obj["inventory_header"].map((entry, i) => {
                  return (
                    <div key={i}>
                      <p
                        tabIndex="0"
                        dangerouslySetInnerHTML={{ __html: entry }}
                      />
                    </div>
                  );
                })}
              </div>
            );
          }
          if (level === 1) {
            return (
              <div className={`c0${level}-container`} key={`${Math.random()}`}>
                <Accordion
                  allowMultipleExpanded={true}
                  allowZeroExpanded={true}
                  preExpanded={[`${index}-${random}`]}
                >
                  <AccordionItem uuid={`${index}-${random}`}>
                    {Object.keys(entry).map((key) => {
                      if (key === "subcontainers") {
                        return diveLevel(entry[key], level + 1);
                      } else {
                        return (
                          <div
                            className={`container-section${
                              "Extent:" in entry[key] ? "-extent" : ""
                            }`}
                            key={`${Math.random()}`}
                          >
                            {key === "container_summary" &&
                              Object.entries(entry[key]).map(
                                ([title, data], i) => {
                                  if (title === "Title:") {
                                    {
                                      {
                                        {
                                          /* console.log("Title Entry: " + data); */
                                        }
                                      }
                                    }
                                    return (
                                      <AccordionItemHeading key={`${Math.random()}`}>
                                        <AccordionItemButton>
                                          <div className="inventory-series">
                                            <p
                                              className="anchor_tag"
                                              key={`${Math.random()}`}
                                              tabIndex="0"
                                              dangerouslySetInnerHTML={{
                                                __html: entry[key][""],
                                              }}
                                            ></p>

                                            <div className="block-text inventory-header">
                                              {title.includes("</strong>") ? (
                                                <p
                                                  tabIndex="0"
                                                  dangerouslySetInnerHTML={{
                                                    __html: title,
                                                  }}
                                                ></p>
                                              ) : (
                                                <p
                                                  className="label"
                                                  tabIndex="0"
                                                  dangerouslySetInnerHTML={{
                                                    __html: title,
                                                  }}
                                                ></p>
                                              )}
                                              <p
                                                className="text "
                                                tabIndex="0"
                                                dangerouslySetInnerHTML={{
                                                  __html: Array.isArray(data)
                                                    ? data
                                                        .map(item =>
                                                          typeof item === "object"
                                                            ? Object.values(item).join(", ") // Extract values from objects
                                                            : item
                                                        )
                                                        .join(", ")
                                                    : typeof data === "object"
                                                    ? Object.values(data).join(", ") // Extract values from objects
                                                    : " " + data,
                                                }}
                                              ></p>
                                            </div>

                                            {"Extent:" in entry[key] && (
                                              <p
                                                key={`${Math.random()}`}
                                                tabIndex="0"
                                                dangerouslySetInnerHTML={{
                                                  __html: "Extent:" + " " + entry[key]["Extent:"],
                                                }}
                                                className="inventory-header"
                                              ></p>
                                            )}

                                            {"Author" in entry[key] && (
                                              <p
                                                key={`${Math.random()}`}
                                                tabIndex="0"
                                                dangerouslySetInnerHTML={{
                                                  __html: "Author: " + entry[key]["Author"],
                                                }}
                                                className="inventory-header"
                                              ></p>
                                            )}
                                          </div>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>

                                    );
                                  } else if (title === "") {
                                    return (
                                      <AccordionItemPanel
                                        key={i}
                                        style={{
                                          visibility: "hidden",
                                          position: "absolute",
                                          left: " -1000px",
                                        }}
                                      >
                                        <div>
                                          {title.includes("</strong>") ? (
                                            <p
                                              tabIndex="0"
                                              dangerouslySetInnerHTML={{
                                                __html: title,
                                              }}
                                            ></p>
                                          ) : (
                                            <p
                                              className="label"
                                              tabIndex="0"
                                              dangerouslySetInnerHTML={{
                                                __html: title,
                                              }}
                                            ></p>
                                          )}
                                          <p
                                            className="text "
                                            tabIndex="0"
                                            dangerouslySetInnerHTML={{
                                              __html: Array.isArray(data)
                                                ? data
                                                    .map(item =>
                                                      typeof item === "object"
                                                        ? Object.values(item).join(", ") // Extract values from objects
                                                        : item
                                                    )
                                                    .join(", ")
                                                : typeof data === "object"
                                                ? Object.values(data).join(", ") // Extract values from objects
                                                : " " + data,
                                            }}
                                          ></p>
                                        </div>
                                      </AccordionItemPanel>
                                    );
                                  } else if (
                                    title !== "Title:" &&
                                    title !== "Extent:"
                                  ) {
                                    return (
                                      <AccordionItemPanel key={i}>
                                        <div key={i}>
                                          {/* Skip rendering "Author" inside the panel if it's already displayed in the heading */}
                                          {title !== "Author" && (
                                            <>
                                          {title.includes("</strong>") ? (
                                            <p
                                              tabIndex="0"
                                              dangerouslySetInnerHTML={{
                                                __html: title,
                                              }}
                                            ></p>
                                          ) : (
                                            <p
                                              className="label"
                                              tabIndex="0"
                                              dangerouslySetInnerHTML={{
                                                __html: title,
                                              }}
                                            ></p>
                                          )}
                                          <p
                                            className="text "
                                            tabIndex="0"
                                            dangerouslySetInnerHTML={{
                                              __html: Array.isArray(data)
                                                ? data
                                                    .map(item =>
                                                      typeof item === "object"
                                                        ? Object.values(item).join(", ") // Extract values from objects
                                                        : item
                                                    )
                                                    .join(", ")
                                                : typeof data === "object"
                                                ? Object.values(data).join(", ") // Extract values from objects
                                                : " " + data,
                                            }}
                                          ></p>
                                          </>
                                          )}
                                        </div>
                                      </AccordionItemPanel>
                                    );
                                  }
                                }
                              )}
                            {key !== "container_summary" &&
                              Object.entries(entry[key]).map(
                                ([title, data], i) => (
                                  <AccordionItemPanel key={i}>
                                    <div key={i}>
                                      {title.includes("</strong>") ? (
                                        <p
                                          tabIndex="0"
                                          dangerouslySetInnerHTML={{
                                            __html: title,
                                          }}
                                        ></p>
                                      ) : (
                                        <p
                                          className="label"
                                          tabIndex="0"
                                          dangerouslySetInnerHTML={{
                                            __html: title,
                                          }}
                                        ></p>
                                      )}
                                      <p
                                        className="text "
                                        tabIndex="0"
                                        dangerouslySetInnerHTML={{
                                          __html: Array.isArray(data)
                                            ? data
                                                .map(item =>
                                                  typeof item === "object"
                                                    ? Object.values(item).join(", ") // Extract values from objects
                                                    : item
                                                )
                                                .join(", ")
                                            : typeof data === "object"
                                            ? Object.values(data).join(", ") // Extract values from objects
                                            : " " + data,
                                        }}
                                      ></p>
                                    </div>
                                  </AccordionItemPanel>
                                )
                              )}
                          </div>
                        );
                      }
                    })}
                  </AccordionItem>
                </Accordion>
              </div>
            );
          } else {
            return (
              <div className={`c0${level}-container`} key={`${Math.random()}`}>
                <AccordionItemPanel>
                  {entry !== undefined &&
                    Object.keys(entry).map((key) => {
                      if (key === "subcontainers") {
                        return diveLevel(entry[key], level + 1);
                      } else {
                        return (
                          <div
                            className="container-section"
                            key={`${Math.random()}`}
                          >
                            {key === "container_summary" &&
                              Object.entries(entry[key]).map(
                                ([title, data], i) => (
                                  <div
                                    className=" block-text subcontainer-title"
                                    key={i}
                                  >
                                    {title.includes("</strong>") ? (
                                      <p
                                        tabIndex="0"
                                        dangerouslySetInnerHTML={{
                                          __html: title,
                                        }}
                                      ></p>
                                    ) : (
                                      <p
                                        className="label"
                                        tabIndex="0"
                                        dangerouslySetInnerHTML={{
                                          __html: title,
                                        }}
                                      ></p>
                                    )}
                                    <p
                                      className="text "
                                      tabIndex="0"
                                      dangerouslySetInnerHTML={{
                                        __html: Array.isArray(data)
                                          ? data
                                              .map(item =>
                                                typeof item === "object"
                                                  ? Object.values(item).join(", ") // Extract values from objects
                                                  : item
                                              )
                                              .join(", ")
                                          : typeof data === "object"
                                          ? Object.values(data).join(", ") // Extract values from objects
                                          : " " + data,
                                      }}
                                    ></p>
                                  </div>
                                )
                              )}
                            {key !== "container_summary" &&
                              Object.entries(entry[key]).map(
                                ([title, data], i) => (
                                  <div className="subcontainer-content" key={i}>
                                    {title.includes("</strong>") ? (
                                      <p
                                        tabIndex="0"
                                        dangerouslySetInnerHTML={{
                                          __html: title,
                                        }}
                                      ></p>
                                    ) : (
                                      <p
                                        className="label"
                                        tabIndex="0"
                                        dangerouslySetInnerHTML={{
                                          __html: title,
                                        }}
                                      ></p>
                                    )}
                                    <p
                                      className="text "
                                      tabIndex="0"
                                      dangerouslySetInnerHTML={{
                                        __html: Array.isArray(data)
                                          ? data
                                              .map(item =>
                                                typeof item === "object"
                                                  ? Object.values(item).join(", ") // Extract values from objects
                                                  : item
                                              )
                                              .join(", ")
                                          : typeof data === "object"
                                          ? Object.values(data).join(", ") // Extract values from objects
                                          : " " + data,
                                      }}
                                    ></p>
                                  </div>
                                )
                              )}
                          </div>
                        );
                      }
                    })}
                </AccordionItemPanel>
              </div>
            );
          }
        })}
      </div>
    );
  }

  return (
    <AccordionItem uuid="inventory" className="summary card">
      <AccordionItemHeading className="card-header">
        <AccordionItemButton>
          <a name={props.name}>
            <p className="card-title accordion-headers">Inventory</p>
          </a>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="card-body">
        <div className="card-text">{diveLevel(props.list, 1)}</div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

const mapStateToProps = (state) => ({
  findingAid: state.findingAids.findingAids,
});

export default connect(mapStateToProps, { searchFindingAids })(Inventory);
